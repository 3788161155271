<template>
    <div id="2_applicant_cellphone_checked_container" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-3/6 xxl:w-1/3 m-0">
      <div class="vx-row no-gutter items-center justify-center p-6">
        <vx-card class="login-card py-3 px-3">
          <div slot="no-body" class="">
            <vs-progress :percent="20" :height="10" color="primary"></vs-progress>
            <div class="vx-col sm:w-full">
              <div class="login-tabs-container">
                <div class="vx-row m-0">
                  <div class="vx-col w-full p-0" style="min-height: 200px">
                    <!-- MAIN-DIV -->
                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5 center">
                      <!-- HEADER -->
                      <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                          <p class="mb-2">PASO 1 DE {{ isMoral ? 6 : 5 }}</p>
                          <p class="text-2xl card-sub-title mb-base">Verifica tu cuenta</p>
                          <p>
                            <template v-if="currentPhoneNumber != null">
                              Te hemos enviado un código por mensaje de texto al
                              siguiente número:
                              <strong>{{ visibleLastNChars(currentPhoneNumber, 4) }}. </strong>
                            </template>
                            <a
                              class="inline-link-primary"
                              target="_blank"
                              rel="noopener"
                              @click="showPhoneForm"
                              >Cambiar número</a
                            >
                          </p>
                        </div>
                      </div>
                      <vs-divider color="primary"></vs-divider>
                      <!-- CONTENT -->
                      <div class="main-form mt-base">
                        <h6>Ingresa el código que recibiste</h6>
                        <div class="vx-row justify-between target">
                            <div class="vx-col w-1/6">
                              <vs-input
                                ref="phone_code_id_0"
                                class="w-full mt-base mb-base code"
                                name="verification_code"
                                v-validate="requiredRules"
                                v-model.lazy="
                                  number[0]
                                "
                                icon-pack="feather"
                                maxlength="1"
                                v-on:keyup="focusNext($event, 1)"
                                @focus="selectOnFocus($event)"
                              />
                            </div>
                            <div class="vx-col w-1/6">
                              <vs-input
                                ref="phone_code_id_1"
                                class="w-full mt-base mb-base code"
                                name="verification_code"
                                v-validate="requiredRules"
                                v-model.lazy="
                                  number[1]
                                "
                                icon-pack="feather"
                                maxlength="1"
                                v-on:keyup="focusNext($event, 2)"
                                v-on:keyup.delete="focusPrev($event, 0)"
                                @focus="selectOnFocus($event)"
                              />
                            </div>
                            <div class="vx-col w-1/6">
                              <vs-input
                                ref="phone_code_id_2"
                                class="w-full mt-base mb-base code"
                                name="verification_code"
                                v-validate="requiredRules"
                                v-model.lazy="
                                  number[2]
                                "
                                icon-pack="feather"
                                maxlength="1"
                                v-on:keyup="focusNext($event, 3)"
                                v-on:keyup.delete="focusPrev($event, 1)"
                                @focus="selectOnFocus($event)"
                              />
                            </div>
                            <div class="vx-col w-1/6">
                              <vs-input
                                ref="phone_code_id_3"
                                class="w-full mt-base mb-base code"
                                name="verification_code"
                                v-validate="requiredRules"
                                v-model.lazy="
                                  number[3]
                                "
                                icon-pack="feather"
                                maxlength="1"
                                v-on:keyup="focusNext($event, 4)"
                                v-on:keyup.delete="focusPrev($event, 2)"
                                @focus="selectOnFocus($event)"
                              />
                            </div>
                            <div class="vx-col w-1/6">
                              <vs-input
                                ref="phone_code_id_4"
                                class="w-full mt-base mb-base code"
                                name="verification_code"
                                v-validate="requiredRules"
                                v-model.lazy="
                                  number[4]
                                "
                                icon-pack="feather"
                                maxlength="1"
                                v-on:keyup="focusNext($event, 5)"
                                v-on:keyup.delete="focusPrev($event, 3)"
                                @focus="selectOnFocus($event)"
                              />
                            </div>
                            <div class="vx-col w-1/6">
                              <vs-input
                                ref="phone_code_id_5"
                                class="w-full mt-base mb-base code"
                                name="verification_code"
                                v-validate="requiredRules"
                                v-model.lazy="
                                  number[5]
                                "
                                icon-pack="feather"
                                maxlength="1"
                                v-on:keyup="focusNext($event, 6, true)"
                                v-on:keyup.delete="focusPrev($event, 4)"
                                @focus="selectOnFocus($event)"
                              />
                            </div>
                          </div>
                        <vs-alert
                          v-if="errorMssg"
                          icon-pack="feather"
                          icon="icon-alert-triangle"
                          class="mb-5"
                          color="danger"
                        >
                          <span class="font-regular">{{ errorMssg }}</span>
                        </vs-alert>
                        <vs-alert
                          v-if="successMssg"
                          icon-pack="feather"
                          icon="icon-check"
                          class="mb-5"
                          color="success"
                        >
                          <span class="font-regular">{{ successMssg }}</span>
                        </vs-alert>
                        <small>
                            {{ whatsappTime == 0 ? `Ahora puedes solicitar uno nuevo.` : `Puedes solicitar un nuevo código en ${ whatsappTime } segundos` }}
                          </small>
                        <div v-if="whatsappTime == 0" class="vx-row mt-2">
                          <div class="vx-col w-full">
                            <verification-code-options
                              class="mb-4"
                              :isDisabled="!showWhatsapp"
                              :phone-text="visibleLastNChars(currentPhoneNumber, 4)"
                              @on-whatsapp-clicked="resendVerificationCodeWhatsapp"
                              @on-sms-clicked="resendVerificationCode"
                            />
                          </div>
                        </div>

                        <div class="vx-row">
                          <div class="vx-col w-full">
                            <div class="mt-4">
                              <vs-button
                                id="2_cellphone_checked_confirm_btn"
                                class="w-full"
                                @click="doVerificationCode"
                                >Validar código</vs-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- END CONTENT DIV -->
                    </div>
                    <!-- END MAIN-DIV -->

                    <!-- PHONE-FORM-DIV -->
                    <div v-if="isFormPhoneVisible" class="px-6 pt-5 pb-5">
                      <!-- HEADER -->
                      <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                          <p class="text-2xl card-sub-title">
                            Ingresa tu teléfono celular.
                          </p>
                        </div>
                      </div>
                      <vs-divider color="dark"></vs-divider>
                      <!-- CONTENT -->
                      <div class="main-form mt-base">
                        <div class="vx-row">
                          <div :class="colClass">
                            <label class="vs-input--label"
                              >Teléfono celular *</label
                            >
                            <div class="flex">
                              <vue-country-code
                                name="dialCode"
                                class="vs-custom"
                                data-vv-name="dialCode"
                                :enableSearchField="true"
                                searchPlaceholderText="Buscar código de país"
                                :enabledCountryCode="true"
                                @onSelect="onSelect"
                                :preferredCountries="['MX']"
                                :danger="hasError('dialCode')"
                                :danger-text="errorText('dialCode')"
                                :success="isSuccess('dialCode')">
                              </vue-country-code>
                              <vs-input
                                class="w-full"
                                label=""
                                name="phone"
                                type="tel"
                                v-validate="'required|numeric'"
                                v-model.lazy="onboardingPhoneData.phone"
                                :danger="hasError('phone')"
                                :danger-text="errorText('phone')"
                                :success="isSuccess('phone')"
                                icon-pack="feather"
                                placeholder="(Requerido)"
                              />
                            </div>
                          </div>
                        </div>
                        <vs-alert
                          v-if="errorMssg"
                          icon-pack="feather"
                          icon="icon-alert-triangle"
                          class="mb-5"
                          color="danger"
                        >
                          <span class="font-regular">{{ errorMssg }}</span>
                        </vs-alert>
                        <div class="vx-row">
                          <div class="vx-col w-full">
                            <div class="flex">
                              <vs-button v-if="currentPhoneNumber"
                                color="dark"
                                class="w-full"
                                @click="showMainOnboardingStep"
                                >Regresar</vs-button
                              >
                              <vs-button
                                class="w-full ml-2"
                                @click="changeUserPhone"
                                >Reenviar código</vs-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- END CONTENT DIV -->
                    </div>
                    <!-- END PHONE-FORM-DIV -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import VerificationCodeOptions from "../../../share/profile/VerificationCodeOptions.vue";

export default {
  mixins: [inputHelper,formatHelper],
  components: {
    VerificationCodeOptions
  },
  props: ["onboardingStepData"],
  data() {
    return {
      requiredRules: "required",
      errorMssg: null,
      successMssg: null,
      colClass: "vx-col w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      isMainOnboardingStepVisible: true,
      isFormPhoneVisible: false,
      onboardingStepDataForm: {
        verification_code: null,
      },
      onboardingPhoneData: {
        country_calling_code: null,
        phone: null,
      },
      showWhatsapp: false,
      whatsappTime: 60,
      timer: null,
      number: []
      
    };
  },
  mounted(){
    if (this.currentPhoneNumber == null) {
      this.isMainOnboardingStepVisible = false;
      this.isFormPhoneVisible = true;
    }
    this.initNipCounter();
    this.pasteCode();
  },
  computed: {
    isMoral() {
      return this.onboardingStepData.user.person_type == 0;
    },
    personal() {
      return this.onboardingStepData.user.personal
    },
    business() {
      return this.onboardingStepData.user.business
    },
    business_owner() {
      return this.business.personal
    },
    currentPhoneNumber() {
      console.log(this.isMoral);
      if(this.isMoral) {
        return this.business_owner.phone.phone;
      } else {
        return this.personal.phone.phone;
      }
    },
    currentPhoneData() {
      if(this.isMoral) {
        return this.business_owner.phone;
      } else {
        return this.personal.phone;
      }
    },
  },
  methods: {
    showMainOnboardingStep() {
      this.isMainOnboardingStepVisible = true;
      this.isFormPhoneVisible = false;
      this.setUpTimerConfig();
      this.initNipCounter();
    },
    showPhoneForm() {
      this.errorMssg = null;
      this.onboardingPhoneData.phone = null;
      this.onboardingPhoneData.country_calling_code = null;
      this.isMainOnboardingStepVisible = false;
      this.isFormPhoneVisible = true;
    },
    async changeUserPhone() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Cambiando el teléfono...");
      try {
        if (this.currentPhoneData.id != null) {
          this.onboardingPhoneData.phone_id = this.currentPhoneData.id
        }
        let payload = {
          applicant_id: this.ApplicantId,
          phone_data: this.onboardingPhoneData,
        };
        await axios.put(
          "/api/applicant-onboarding/put/update-applicant-phone",
          payload
        );
        this.showMainOnboardingStep();

        this.showSuccess("Teléfono cambiado exitosamente");
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    resendVerificationCodeWhatsapp(){
      this.resendVerificationCode(true);
    },
    async resendVerificationCode(isWhatsapp = false) {
      this.errorMssg = null;
      this.showLoading(true, "Reenviando el código...");
      try {
        await axios.post(
          `/api/applicant-onboarding/post/send-verify-code?whatsapp=${ isWhatsapp }`,
          {
            applicant_id: this.ApplicantId
          }
        );
        this.setUpTimerConfig();
        this.initNipCounter();
        this.showSuccess("Código enviado exitosamente");
      } catch (e) {
        this.error_message =
          "Ocurrió  un error al obtener los datos, inténtalo mas tarde.";
      }
      this.showLoading(false);
    },
    async doVerificationCode(hasToValidate = true) {
      this.errorMssg = null;
      if(hasToValidate) {
        if (!(await this.validateForm())) {
          return;
        }
      }
      this.showLoading(true, "Validando tu código...");

      const phone = this.number.join('');

      try {
        let payload = {
          applicant_id: this.ApplicantId,
          verification_code: phone,
        };
        await axios.post(
          "/api/applicant-onboarding/post/verify-phone",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      return this.$validator.validateAll();
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
      setTimeout(() => this.errorMssg = null, 5000)
    },
    showSuccess(message) {
      this.successMssg = message;
      setTimeout(() => this.successMssg = null, 5000)
    },
    async goToLogin() {
      await this.$router.replace({ name: "login" });
    },
    onSelect({ dialCode }) {
      this.onboardingPhoneData.country_calling_code = `+${dialCode}`;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
    setUpTimerConfig() {
      window.clearInterval(this.timer);
      this.whatsappTime = 60;
      this.showWhatsapp = false;
    },
    initNipCounter() {
      this.timer = setInterval(() => {
        --this.whatsappTime;
        if(this.whatsappTime == 0){
          this.showWhatsapp = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    focusNext(event, nextIndex, isLast = false) {
      if (this.isNumber(event) && event.target.value.length > 0) {
        if(!isLast) {
          this.$refs[`phone_code_id_${nextIndex}`].focusInput();
        } else {
          this.doVerificationCode();
        }
      }
    },
    focusPrev(event, prevIndex) {
      if (event.target.value.length == 0) {
        this.$refs[`phone_code_id_${prevIndex}`].focusInput();
      }
    },
    selectOnFocus(event) {
      event.target.select();
    },
    isNumber(event) {
      const numberValue = event.target.value
      if (numberValue >= 0 && numberValue <= 9) {
        this.errorMssg = null;
        return true;
      }
      this.errorMssg = "Tu código de verificación solo contiene números. Por favor, verifica que sea correcto.";
      return false;
    },
    pasteCode()
    {
      const target = document.querySelector("div.target");
      const me = this;
      target.addEventListener("paste", (event) => {
        event.preventDefault();
        let flag = true
        
        let paste = (event.clipboardData || window.clipboardData).getData("text");
        paste = paste.toUpperCase();

        const code = paste.split("");
        for (let i = 0; i < code.length; i++) {
          // Sí es numero lo dejo pasar
          if(code.length == 6 && code[i] >= 0 && code[i] <= 9) {
            this.number[i] = code[i];
            this.errorMssg = null;
            flag = true;
          } else {
            flag = false;
            this.number = [];
            this.errorMssg = "Tu código de verificación solo debe contener números. Por favor, verifica que sea correcto el código copiado.";
            break;
          }
        }
        // Sí la bandera no cambio, se valida el código.
        if(flag && this.number.length == 6) {
          this.doVerificationCode(false);
        }
      });
    }
  },
};
</script>

<style>
.center {
  text-align-last: center;
}

.code .vs-con-input input[type="text"]{
  border: 1.5px solid #28de18 !important;
  font-weight: bold;
}
</style>
