<template>
    <div id="4_applicant-onboarding-birth-data" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-3/6 xxl:w-1/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="100" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="mb-2">PASO 5 DE 5</p>
                                                <p class="text-2xl card-sub-title">Confirma tus datos</p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="main-form mt-base">
                                            <div class="vx-row">
                                                <div class="vx-col w-full mb-4">
                                                    <vs-list>
                                                        <!-- NOMBRE -->
                                                        <template v-if="!isNameFormVisible">
                                                            <vs-list-item title="Nombre" :subtitle="personalFullName">
                                                                <vs-button @click.stop="showStepForm(1)" color="primary" type="line" size="small">Editar</vs-button>
                                                            </vs-list-item>
                                                        </template>
                                                        <template v-else>
                                                            <div class="vx-row">
                                                                <div :class="colClass">
                                                                    <vs-input
                                                                    @input="(val) => (currentFirstName = currentFirstName.toUpperCase())"
                                                                    class="w-full"
                                                                    label="Primer nombre"
                                                                    name="currentFirstName"
                                                                    v-validate="requiredRules"
                                                                    v-model.lazy="currentFirstName"
                                                                    :danger="hasError('currentFirstName')"
                                                                    :danger-text="errorText('currentFirstName')"
                                                                    :success="isSuccess('currentFirstName')"
                                                                    icon-pack="feather"
                                                                    :placeholder="requiredPlaceholder"
                                                                    />
                                                                </div>
                                                                <div :class="colClass">
                                                                    <vs-input
                                                                    @input="(val) => (currentSecondName = currentSecondName.toUpperCase())"
                                                                    class="w-full"
                                                                    label="Segundo nombre"
                                                                    name="currentSecondName"
                                                                    v-model.lazy="currentSecondName"
                                                                    :danger="hasError('currentSecondName')"
                                                                    :danger-text="errorText('currentSecondName')"
                                                                    :success="isSuccess('currentSecondName')"
                                                                    icon-pack="feather"
                                                                    :placeholder="optionalPlaceholder"
                                                                    />
                                                                </div>
                                                                <div :class="colClass">
                                                                    <vs-input
                                                                    @input="(val) => (currentLastName1 = currentLastName1.toUpperCase())"
                                                                    class="w-full"
                                                                    label="Primer apellido"
                                                                    name="currentLastName1"
                                                                    v-validate="requiredRules"
                                                                    v-model.lazy="currentLastName1"
                                                                    :danger="hasError('currentLastName1')"
                                                                    :danger-text="errorText('currentLastName1')"
                                                                    :success="isSuccess('currentLastName1')"
                                                                    icon-pack="feather"
                                                                    :placeholder="requiredPlaceholder"
                                                                    />
                                                                </div>
                                                                <div :class="colClass">
                                                                    <vs-input
                                                                    @input="(val) => (currentLastName2 = currentLastName2.toUpperCase())"
                                                                    class="w-full"
                                                                    label="Segundo apellido"
                                                                    name="currentLastName2"
                                                                    v-model.lazy="currentLastName2"
                                                                    :danger="hasError('currentLastName2')"
                                                                    :danger-text="errorText('currentLastName2')"
                                                                    :success="isSuccess('currentLastName2')"
                                                                    icon-pack="feather"
                                                                    :placeholder="optionalPlaceholder"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </template>

                                                        <!-- GÉNERO -->
                                                        <template v-if="!isGenderFormVisible">
                                                            <vs-list-item title="Género" :subtitle="personalGender.toUpperCase()">
                                                                <vs-button @click.stop="showStepForm(2)" color="primary" type="line" size="small">Editar</vs-button>
                                                            </vs-list-item>
                                                        </template>
                                                        <template v-else>
                                                            <div class="vx-row">
                                                                <div :class="colClass" v-for="gender in genderOptions" :key="gender.value">
                                                                    <rg-radio class="w-full" groupName="gender" :label="gender.label" :value="gender.value" v-model="currentGender" />
                                                                </div>
                                                            </div>
                                                        </template>

                                                        <!-- FECHA Y LUGAR DE NACIMIENTO -->
                                                        <template v-if="!isBirthDateFormVisible">
                                                            <vs-list-item title="Fecha y luigar de nacimiento" :subtitle="`${currentBirthDate}   |    ${currentBirthStateName}`">
                                                                <vs-button @click.stop="showStepForm(3)" color="primary" type="line" size="small">Editar</vs-button>
                                                            </vs-list-item>
                                                        </template>
                                                        <template v-else>
                                                            <div class="vx-row mb-4">
                                                                <div :class="colClassFull">
                                                                    <label class="vs-input--label">Fecha de nacimiento</label>
                                                                    <datepicker
                                                                    class="w-full"
                                                                    v-model.lazy="currentBirthDate"
                                                                    name="currentBirthDate"
                                                                    :language="lang_es"
                                                                    v-validate="requiredRules"
                                                                    :danger="hasError('currentBirthDate')"
                                                                    :danger-text="errorText('currentBirthDate')"
                                                                    :success="isSuccess('currentBirthDate')"
                                                                    format="dd/MM/yyyy"
                                                                    initial-view="year"
                                                                    :disabled-dates="disabledDates"
                                                                    :placeholder="requiredPlaceholder"
                                                                    :use-utc="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="vx-row mb-2">
                                                                <div :class="colClassFull">
                                                                    <label class="vs-input--label">Estado de nacimiento</label>
                                                                    <v-select
                                                                    class="vs-custom"
                                                                    name="currentBirthState"
                                                                    :class="{
                                                                        'has-error': hasError('currentBirthState'),
                                                                        'is-success': isSuccess('currentBirthState'),
                                                                    }"
                                                                    v-model.lazy="currentBirthState"
                                                                    v-validate="requiredRules"
                                                                    :options="collections.states"
                                                                    :reduce="(item) => item.value"
                                                                    :clearable="false"
                                                                    placeholder="Selecciona una opción"
                                                                    :searchable="false"
                                                                    >
                                                                    </v-select>
                                                                    <v-error v-if="hasError('currentBirthState')" :error="errorText('currentBirthState')" />
                                                                </div>
                                                            </div>
                                                            <div v-if="currentBirthState == birthStateIdForeign" class="vx-row">
                                                            <div class="vx-col w-full mt-2">
                                                                <label class="vs-input--label"
                                                                >País de nacimiento</label
                                                                >
                                                                <v-select
                                                                class="vs-custom"
                                                                name="currentOriginCountry"
                                                                :class="{
                                                                    'has-error': hasError('currentOriginCountry'),
                                                                    'is-success': isSuccess('currentOriginCountry'),
                                                                }"
                                                                v-model.lazy="currentOriginCountry"
                                                                v-validate="requiredRules"
                                                                :options="collections.countries"
                                                                :reduce="(item) => item.value"
                                                                :clearable="false"
                                                                placeholder="Selecciona una opción"
                                                                :searchable="false"
                                                                >
                                                                </v-select>
                                                                <v-error v-if="hasError('currentOriginCountry')" :error="errorText('currentOriginCountry')" />
                                                            </div>
                                                            <div class="vx-col w-full mt-5">
                                                                <label class="vs-input--label"
                                                                >Nacionalidad</label
                                                                >
                                                                <v-select
                                                                class="vs-custom"
                                                                name="currentCitizenship"
                                                                :class="{
                                                                    'has-error': hasError('currentCitizenship'),
                                                                    'is-success': isSuccess('currentCitizenship'),
                                                                }"
                                                                v-model.lazy="currentCitizenship"
                                                                v-validate="requiredRules"
                                                                :options="collections.countries"
                                                                :reduce="(item) => item.value"
                                                                :clearable="false"
                                                                placeholder="Selecciona una opción"
                                                                :searchable="false"
                                                                >
                                                                </v-select>
                                                                <v-error v-if="hasError('currentCitizenship')" :error="errorText('currentCitizenship')" />
                                                            </div>
                                                            </div>
                                                        </template>

                                                        <!-- RFC -->
                                                        <template v-if="!isRfcFormVisible">
                                                            <vs-list-item title="RFC" :subtitle="currentRfc">
                                                                <vs-button @click.stop="showStepForm(4)" color="primary" type="line" size="small">Editar</vs-button>
                                                            </vs-list-item>
                                                        </template>
                                                        <template v-else>
                                                            <div class="vx-row mt-4">
                                                                <div :class="colClassFull">
                                                                    <label for="currentRfc" class="">RFC</label>
                                                                    <vs-input
                                                                        @input="(val) => (currentRfc = currentRfc.toUpperCase())"
                                                                        class="w-full"
                                                                        name="currentRfc"
                                                                        v-validate="requiredRules"
                                                                        v-model.lazy="currentRfc"
                                                                        :danger="hasError('currentRfc')"
                                                                        :danger-text="errorText('currentRfc')"
                                                                        :success="isSuccess('currentRfc')"
                                                                        icon-pack="feather"
                                                                        :placeholder="requiredPlaceholder"
                                                                    />
                                                                    <a
                                                                        class="inline-link-primary"
                                                                        target="_blank"
                                                                        rel="noopener"
                                                                        :href="$sanitizeUrl(rfcUrl)">Consultar mi RFC</a>
                                                                </div>
                                                            </div>
                                                        </template>

                                                        <!-- CURP -->
                                                        <template v-if="!isCurpFormVisible">
                                                            <vs-list-item title="CURP" :subtitle="currentCurp">
                                                                <vs-button @click.stop="showStepForm(5)" color="primary" type="line" size="small">Editar</vs-button>
                                                            </vs-list-item>
                                                        </template>
                                                        <template v-else>
                                                            <div class="vx-row mt-4">
                                                                <div :class="colClassFull">
                                                                    <label for="currentCurp" class="">CURP</label>
                                                                    <vs-input
                                                                        @input="(val) => (currentCurp = currentCurp.toUpperCase())"
                                                                        class="w-full"
                                                                        name="currentCurp"
                                                                        v-validate="requiredRules"
                                                                        v-model.lazy="currentCurp"
                                                                        :danger="hasError('currentCurp')"
                                                                        :danger-text="errorText('currentCurp')"
                                                                        :success="isSuccess('currentCurp')"
                                                                        icon-pack="feather"
                                                                        :placeholder="requiredPlaceholder"
                                                                    />
                                                                    <a
                                                                        class="inline-link-primary"
                                                                        target="_blank"
                                                                        rel="noopener"
                                                                        :href="$sanitizeUrl(curpUrl)">Consultar mi CURP</a>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </vs-list>
                                                </div>

                                                <div class="vx-col w-full mb-base">
                                                    <div class="flex mb-4">
                                                        <vs-checkbox v-model="dataChecked"
                                                            name="legal" v-validate="requiredRules"
                                                            :danger="hasError('legal')"
                                                            :danger-text="errorText('legal')"
                                                            :success="isSuccess('legal')"></vs-checkbox>
                                                        <p class="text-sm text-break">
                                                            Confirmo que los datos aquí presentados corresponden a mi persona.
                                                            Confirmo que actúo por cuenta propia. <br>
                                                            Al hacer clic en “Confirmar y continuar” firmas electrónicamente nuestro 
                                                            <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(comisionMercantilUrl)">Contrato de Comisión Mercantil</a> (lo cual nos permite levantar fondos para tu proyecto).
                                                            <!-- Soy dueño de los recursos y éstos han sido obtenidos de manera lícita. -->
                                                        </p>
                                                    </div>
                                                    <div class="flex mb-4">
                                                        <vs-checkbox v-model="advertisingChecked"
                                                            name="advertising" v-validate="requiredRules"
                                                            :danger="hasError('advertising')"
                                                            :danger-text="errorText('advertising')"
                                                            :success="isSuccess('advertising')"></vs-checkbox>
                                                        <p class="text-sm">
                                                            Autorizo a RedGirasol que me envíe publicidad y que use mis datos para mejorar mi experiencia y recibir promociones que se ajusten a mis necesidades.
                                                        </p>
                                                    </div>

                                                    <span>
                                                        <vs-icon size="14px" icon="info_outline"></vs-icon>
                                                        <small>
                                                            <i>
                                                                En caso de que alguna de las declaraciones anteriores
                                                            sea falsa o incorrecta, por ley deberemos bloquear tu cuenta.
                                                            </i>
                                                        </small>
                                                    </span>
                                                </div>

                                                <vs-alert
                                                    v-if="errorMssg"
                                                    icon-pack="feather"
                                                    icon="icon-alert-triangle"
                                                    class="mb-5"
                                                    color="danger">
                                                        <span class="font-regular">{{ errorMssg }}</span>
                                                </vs-alert>
                                            </div>
                                            <div class="vx-row justify-center">
                                                <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button id="6_rfc_curp_confirm_btn" class="w-full mt-4" color="dark" @click="false" type="border">Atrás</vs-button>
                                                </div> -->
                                                <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button id="6_rfc_curp_confirm_btn" class="w-full mt-4 pl-1 pr-1" color="dark" @click="saveDataForm">Confirmar y continuar</vs-button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
    name: "ApplicantOnboardingBirthData",
    props: ["onboardingStepData"],
    mixins: [inputHelper, dateHelper],
    components: {
        Datepicker
    },
    data: () => ({
        dataChecked: false,
        advertisingChecked: true,
        isMounted: false,
        mexicoId: 700,
        rfcUrl: "https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp",
        curpUrl: "https://www.gob.mx/curp/",
        lang_es: es,
        collections: {
            states: [],
            countries: [],
        },
        disabledDates: {
            from: new Date(),
        },
        requiredRules: "required",
        errorMssg: null,
        colClassFull: "vx-col w-full",
        colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        genderOptions: [
            {"value": 2, "label": "Mujer"},
            {"value": 1, "label": "Hombre"},
            // {"value": 0, "label": "No definido"},
        ],
        currentFirstName: null,
        currentSecondName: null,
        currentLastName1: null,
        currentLastName2: null,
        currentGender: null,
        currentBirthDate: null,
        currentBirthState: null,
        currentOriginCountry: null,
        currentCitizenship: null,
        currentRfc: null,
        currentCurp: null,
        isNameFormVisible: false,
        isGenderFormVisible: false,
        isBirthDateFormVisible: false,
        isBirthStateFormVisible: false,
        isRfcFormVisible: false,
        isCurpFormVisible: false,
        // showConfirmationCard:false
        birthStateIdForeign:null,
        applicantContractUrl: 'contrato-de-comision-mercantil-cliente',
        applicantContractType: 4
    }),
    async beforeMount(){
        this.isMounted = false;
        this.setData();
        this.isMounted = true;
    },
    computed: {
        comisionMercantilUrl(){
            return `${this.WebSiteUrl}/${this.applicantContractUrl}`;
        },
        isMoral() {
            this.onboardingStepData.user.person_type == 0;
        },
        personal() {
            return this.onboardingStepData.user.personal
        },
        business() {
            return this.onboardingStepData.user.business
        },
        business_owner() {
            return this.business.personal
        },
        currentPersonal() {
            return this.isMoral ? this.business_owner : this.personal
        },
        personalFullName () {
            return this.currentPersonal.first_name + " " + (this.currentPersonal.second_name || '') + " " + this.currentPersonal.last_name_1 + " " + (this.currentPersonal.last_name_2||'');
        },
        personalGender()
        {
            let gender = "No definido";
            if(this.currentPersonal.gender == 1) {
                gender = "Hombre";
            } else if(this.currentPersonal.gender == 2) {
                gender = "Mujer";
            }

            return gender;
        },
        currentBirthStateName() {
            return this.currentPersonal.birth_state_named;
        },
    },
    methods: {
        async showStepForm(option) {
            switch (option) {
                case 1:
                    this.isNameFormVisible = true;
                break;
                case 2:
                    this.isGenderFormVisible = true;
                break;
                case 3:
                    this.showLoading(true);
                    await this.getCollections();
                    this.showLoading(false);
                    this.isBirthDateFormVisible = true;          
                break;
                case 4:
                    this.isRfcFormVisible = true;
                break;
                case 5:
                    this.isCurpFormVisible = true;
                break;
                default:
                break;
            }
        },
        async saveDataForm() {
            this.errorMssg = null;

            if(!this.dataChecked) {
                this.errorMssg = "Es necesario que confirmes que los datos aquí presentados corresponden a tu persona.";
                return;
            }

            if (!(await this.validateForm())) {
                return;
            }
            this.showLoading(true, "Guardando información...");
            try {
                if (this.currentBirthState != this.birthStateIdForeign) {
                    this.currentOriginCountry = this.mexicoId;
                    this.currentCitizenship = this.mexicoId;
                }
                let payload = {
                    applicant_id: this.ApplicantId,
                    first_name: this.currentFirstName,
                    second_name: this.currentSecondName,
                    last_name_1: this.currentLastName1,
                    last_name_2: this.currentLastName2,
                    gender: this.currentGender,
                    birth_state: this.currentBirthState,
                    birth_date: this.currentBirthDate,
                    origin_country: this.currentOriginCountry,
                    citizenship: this.currentCitizenship,
                    rfc: this.currentRfc,
                    curp: this.currentCurp,
                    advertising_checked: this.advertisingChecked,
                    contractType: this.applicantContractType
                };
                await axios.post(
                    "/api/applicant-onboarding/post/finish-onboarding",
                    payload
                );
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        setData() {
            this.currentFirstName = this.currentPersonal.first_name;
            this.currentSecondName = this.currentPersonal.second_name;
            this.currentLastName1 = this.currentPersonal.last_name_1;
            this.currentLastName2 = this.currentPersonal.last_name_2;
            this.currentGender = this.currentPersonal.gender;
            this.currentBirthDate = this.currentPersonal.birth_date;
            this.currentBirthState = this.currentPersonal.birth_state;
            this.currentOriginCountry = this.currentPersonal.origin_country;
            this.currentCitizenship = this.currentPersonal.citizenship;
            this.currentRfc = this.currentPersonal.rfc;
            this.currentCurp = this.currentPersonal.curp;
        },
        async getCollections(){
            try {
                let collectionsObjects = ['countriesList', 'statesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
                let colls = res.data;
                colls.statesList.forEach(opt => {
                if (opt.name == "EXTRANJERO (FOREIGN)") {
                    this.birthStateIdForeign = parseInt(opt.id);
                }
                this.collections.states.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
                })
                colls.countriesList.forEach(opt => {
                    if (opt.id != 700 && opt.id != 600 && opt.id != 999) 
                        this.collections.countries.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
                })
            }
                catch (e) {
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        showError(e) {
            this.warn(e);
            let err = "Ha ocurrido un error con la operación, intente más tarde";
            if (e.response && e.response.data && e.response.data.error) {
                err = e.response.data.error;
            }
            this.errorMssg = err;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return (ff && ff.required && ff.valid);
        },
    }
}
</script>